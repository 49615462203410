import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { ARButton } from "three/examples/jsm/webxr/ARButton";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const Cube = () => {
  const canvasRef = useRef(null);
  const modelRef = useRef(null);
  const cameraRef = useRef(null);
  const [selectedModel, setSelectedModel] = useState("earth"); // Default to 'earth'
  const arButtonRef = useRef(null); // Store the AR button

  // Track user interaction
  const isDraggingRef = useRef(false);
  const previousMousePosition = useRef({ x: 0, y: 0 });

  // Rotation speed when not interacted with
  const rotationSpeed = 0.01;

  useEffect(() => {
    const scene = new THREE.Scene();

    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    const light = new THREE.AmbientLight(0xffffff, 6.9);
    scene.add(light);

    const loader = new GLTFLoader();

    const loadModel = (modelUrl) => {
      console.log({modelUrl});
      loader.load(
        modelUrl,
        (gltf) => {
          if (modelRef.current) {
            scene.remove(modelRef.current); // Remove previous model
          }
          modelRef.current = gltf.scene;
          modelRef.current.position.set(0, 0, -2);
          modelRef.current.scale.set(0.5, 0.5, 0.5);

          scene.add(modelRef.current);
        },
        (xhr) => {
          console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
        },
        (error) => {
          console.error("An error occurred while loading the model", error);
        }
      );
    };

    // Load the selected model when it changes
    switch (selectedModel) {
      case "earth":
        loadModel(
          "https://vopa-api.s3.ap-south-1.amazonaws.com/planet_earth/scene.gltf" // GLTF file with embedded texture
        );
        break;
      // case "model2":
      //   loadModel("/models/amethyst_crystal/scene.gltf");
      //   break;
      case "model3":
        loadModel("https://vopa-api.s3.ap-south-1.amazonaws.com/baby_octopus/scene.gltf");
        break;
      default:
        break;
    }

    const camera = new THREE.PerspectiveCamera(
      75,
      sizes.width / sizes.height,
      0.1,
      1000
    );
    camera.position.set(0, 2, 5);
    camera.lookAt(new THREE.Vector3(0, 0, 0));
    scene.add(camera);
    cameraRef.current = camera;

    const renderer = new THREE.WebGLRenderer({
      canvas: canvasRef.current,
      antialias: true,
      alpha: true,
    });

    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.xr.enabled = true;

    // Ensure ARButton is only added once
    if (!arButtonRef.current) {
      arButtonRef.current = ARButton.createButton(renderer);
      document.body.appendChild(arButtonRef.current);

      arButtonRef.current.style.position = "fixed";
      arButtonRef.current.style.bottom = "20px";
      arButtonRef.current.style.backgroundColor = "#4CAF50";
      arButtonRef.current.style.color = "white";
      arButtonRef.current.style.borderRadius = "8px";
      arButtonRef.current.style.fontSize = "12px";
      arButtonRef.current.style.cursor = "pointer";
    }

    const animate = () => {
      if (modelRef.current && !isDraggingRef.current) {
        // Default rotation when not dragging
        modelRef.current.rotation.y += rotationSpeed;
      }
      renderer.render(scene, camera);
    };

    renderer.setAnimationLoop(animate);

    const handleResize = () => {
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;

      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();

      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(window.devicePixelRatio);
    };

    const handleWheel = (event) => {
      event.preventDefault();
      const zoomSpeed = 0.1;
      camera.position.z += event.deltaY * zoomSpeed;
      camera.position.z = Math.max(1, camera.position.z);
    };

    // Mouse and touch events to rotate the model
    const onMouseDown = (event) => {
      isDraggingRef.current = true;
      previousMousePosition.current = { x: event.clientX, y: event.clientY };
    };

    const onMouseMove = (event) => {
      if (isDraggingRef.current && modelRef.current) {
        const deltaX = event.clientX - previousMousePosition.current.x;
        const deltaY = event.clientY - previousMousePosition.current.y;
        previousMousePosition.current = { x: event.clientX, y: event.clientY };

        // Rotate the model according to mouse movement
        modelRef.current.rotation.y += deltaX * 0.005;
        modelRef.current.rotation.x += deltaY * 0.005;
      }
    };

    const onMouseUp = () => {
      isDraggingRef.current = false; // Stop dragging
    };

    // Touch equivalent events for mobile devices
    const onTouchStart = (event) => {
      isDraggingRef.current = true;
      previousMousePosition.current = {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      };
    };

    const onTouchMove = (event) => {
      if (isDraggingRef.current && modelRef.current) {
        const deltaX =
          event.touches[0].clientX - previousMousePosition.current.x;
        const deltaY =
          event.touches[0].clientY - previousMousePosition.current.y;
        previousMousePosition.current = {
          x: event.touches[0].clientX,
          y: event.touches[0].clientY,
        };

        // Rotate the model according to touch movement
        modelRef.current.rotation.y += deltaX * 0.005;
        modelRef.current.rotation.x += deltaY * 0.005;
      }
    };

    const onTouchEnd = () => {
      isDraggingRef.current = false; // Stop dragging
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("wheel", handleWheel, { passive: false });
    window.addEventListener("mousedown", onMouseDown);
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
    window.addEventListener("touchstart", onTouchStart);
    window.addEventListener("touchmove", onTouchMove);
    window.addEventListener("touchend", onTouchEnd);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("mousedown", onMouseDown);
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("touchstart", onTouchStart);
      window.removeEventListener("touchmove", onTouchMove);
      window.removeEventListener("touchend", onTouchEnd);
      renderer.dispose();
    };
  }, [selectedModel]); // Re-run useEffect when selectedModel changes

  return (
    <div>
      <div style={{ position: "absolute", zIndex: 1 }}>
        {/* Create five buttons to choose different models */}
        <button
          style={{
            backgroundColor: "#4CAF50",
            color: "white",
            padding: "10px 20px",
            margin: "10px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
          }}
          onClick={() => setSelectedModel("earth")}
        >
          Show Earth
        </button>
        {/* <button
          style={{
            backgroundColor: "#2196F3",
            color: "white",
            padding: "10px 20px",
            margin: "10px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
          }}
          onClick={() => setSelectedModel("model2")}
        >
          Show Flower
        </button> */}
        <button
          style={{
            backgroundColor: "#FFC107",
            color: "white",
            padding: "10px 20px",
            margin: "10px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
          }}
          onClick={() => setSelectedModel("model3")}
        >
          Show Octopus
        </button>
      </div>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default Cube;
