import React, { lazy, useRef, useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import { useState } from "react";
import { UserContext } from "./context/UserContext";
import { getWebTimeSpend } from "./dataFromServer";
// import Demo from "./pages/Demo";
// import CubeContainer from "./pages/CubeContainer";
import Cube from "./pages/Cube";
// import ReactGA from 'react-ga';
// const TRACKING_ID = "G-DZYH7BD5EX"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const Profile = lazy(() => import("./pages/Profile"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Home = lazy(() => import("./pages/Home"));

function App() {
  const pageHeading = useContext(UserContext);

  // const pageHeading=useContext(UserContext);
  const sectionTheme = pageHeading.sectionTheme;

  const [counter, setCounter] = useState(5);

  function handleRouteHome(routeLink) {
    console.log("RouteLink->app", routeLink);

    if (routeLink == "/app/SpecialCourses") {
      console.log("RouteLink", "Came inside");
      pageHeading.changeSection(sectionTheme[1]);
    }

    if (routeLink == "/app/Home") {
      console.log("RouteLink", "Came inside");
      pageHeading.changeSection(sectionTheme[0]);
    }

    if (routeLink == "/app/Bookmarks") {
      console.log("RouteLink", "Came inside");
      pageHeading.changeSection(sectionTheme[2]);
    }
  }

  // useEffect(() => {
  //   let d=5;
  //   const interval = setInterval(() => {
  //     console.log('d is incremented to'+counter);
  //     // setCounter(time=>time+5);

  //     if(d%20==0)
  //     {

  //     }
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, []);

  // useEffect(() => {
  //   const handleTabClose = event => {
  //     event.preventDefault();

  //     console.log('beforeunload event triggered');

  //     return (event.returnValue = 'Are you sure you want to exit?');
  //   };

  //   window.addEventListener('beforeunload', handleTabClose);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleTabClose);
  //   };
  // }, []);

  console.log("local->" + localStorage.getItem("login"));

  // const [profileSelect,setProfileSelect]=useState(()=>{
  //   if(localStorage.getItem('profile'))
  //   {
  //     console.log("Profile is selected");
  //     return localStorage.getItem('profile');
  //   }
  //   else
  //   {
  //     console.log("profile is not selected");
  //     return "";
  //   }
  //   });

  //   console.log("profile->"+localStorage.getItem('profile'));

  // const [login,setLogin]=useState(()=>{
  //   if(localStorage.getItem('login')&&localStorage.getItem("login")==="true")
  //   {
  //     console.log("Login is true");
  //     return true;
  //   }
  //   else
  //   {
  //     console.log("Login is false");
  //     setProfileSelect("");
  //     return false;
  //   }
  //   });

  return (
    <>
      {console.log("Profile is " + pageHeading.profile)}
      {/* /V-school-app */}
      <Router basename="/V-school-app" className="GeeksForGeeks">
        <AccessibleNavigationAnnouncer />

        {!pageHeading.login ? (
          <Switch>
            <Route exact path="/" render={() => <Login />} />
            <Route path="/login" render={() => <Login />} />
            <Route exact path="/webxr" render={() => <Cube />} />
            <Redirect from="*" to="/" />
          </Switch>
        ) : !pageHeading.profile ? (
          <Switch>
            <Route exact path="/profile" render={() => <Profile />} />
            <Route exact path="/webxr" render={() => <Cube/>} />
            <Redirect from="*" to="/profile" />
          </Switch>
        ) : (
          <Switch>
            {/* <Route path='/subject/:subject' component={Home} ></Route>  */}
            {/* <Route path="/app/Home/:subject" component={Layout} /> */}
            <Route path="/app" component={Layout} />
            <Route path="/app/Home" component={Home} />
            <Route exact path="/webxr" render={() => <Cube />} />
            <Route
              path="/app/SpecialCourses"
              render={() => handleRouteHome("/app/SpecialCourses")}
            />

            {/* <Route path="/profile" component={Profile} /> */}
            {/* <Redirect exact from="/" component={Layout} /> */}
            <Redirect exact from="/" to="/app/Home" />
            <Redirect exact from="/" to="/app/Home" />
            <Redirect from="*" to="/app/Home" />
          </Switch>
        )}
      </Router>
    </>
  );
}

export default App;
